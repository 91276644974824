/* @import "~antd/dist/antd.less"; // Import Ant Design styles by less entry
@import "~antd/lib/style/color/colorPalette.less";
@import "~antd/dist/antd.less";
@import "~antd/lib/style/themes/dark.less";

//custom less
@import "./index.less";
@import "./assets/less/custom-antd.less"; */

@import "antd/dist/antd.variable.min.css";

:root {
  --PRIMARY_COLOR_ONE: #246F6C;
  --PRIMARY_COLOR_ONE_DARK: #1b5754;
  --PRIMARY_COLOR_TWO: #3c3c3b;
  --white: #faf0e6;
  --ERROR_COLOR: #ff4d4f;
  --SECONDARY_1_COLOR: #ebe6e3;
  --SECONDARY_2_COLOR: #89a09b;
  --SECONDARY_3_COLOR: #c9bab1;
  --SECONDARY_4_COLOR: #246f6c;
  --SECONDARY_5_COLOR: #ededed;
  --SECONDARY_6_COLOR: rgba(137, 160, 155, 0.63);

}

.blur-bg{
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}



 .ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor{
  min-height:150px;
}

.ant-badge-count{
  background: var(--PRIMARY_COLOR_ONE) !important;
}

.ant-btn{
  border-radius: 5px !important;
}
.drag-n-drop {
  border-style: dashed;
  border-width: 2px;
  border-color: var(--ant-primary-2);
  padding: 10px;
}

.custom-button-bootstrap {
  background: var(--SECONDARY_4_COLOR);
  border-color: var(--SECONDARY_4_COLOR);
}


.custom-button-bootstrap:hover {
  background: var(--SECONDARY_4_COLOR);
  border-color: var(--SECONDARY_4_COLOR);
}

.main-color-border {
  border-color: var(--PRIMARY_COLOR_ONE);
}


.main-color-border:hover {
  border-color: var(--PRIMARY_COLOR_ONE_DARK);
}

.main-color {
  color: var(--PRIMARY_COLOR_ONE) !important;
}

.custom-grey-color {
  color: var(--PRIMARY_COLOR_TWO) !important;
}
.custom-grey-bg {
  background: var(--PRIMARY_COLOR_TWO) !important;
}

.primary-bg {
  background: var(--PRIMARY_COLOR_ONE) !important;
}
.danger-bg {
  background: var(--ERROR_COLOR) !important;
}
.secondary-6-bg {
  background: var(--SECONDARY_6_COLOR) !important;
}

.custom-grey-outline {
  border-color: var(--PRIMARY_COLOR_TWO);
}

.custom-grey-outline:hover {
  border-color: var(--PRIMARY_COLOR_TWO);
}

.paper{
  background: white;
  padding: 1rem 2rem;
  border-radius: 7px;
  height: fit-content;
  overflow: hidden !important;
}

.toolbar_header {
  height: 56px;
  padding: 0.75rem;
  display: flex;
}

.page {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.pre-line{
  white-space: pre-line;
}

.font-size-10 {
  font-size: 10px !important;
}

.font-size-11 {
  font-size: 11px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font {
  font-family: "Poppins";
}

html {
  box-sizing: border-box;
  font-family: "Poppins", "Roboto", "Times New Roman", Times, serif !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

h2 {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.25;
  color: #2a2d32;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
h1 {
  font-size: 24px;
  font-weight: bold;
  color: #2a2d32;
  line-height: 30px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.h3,
h3 {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

body {
  font-family: "Poppins";
  font-size: 14px;
}

.border-radius-10{
  border-radius: 10px;
}

.border-radius-5{
  border-radius: 5px;
}


.border-radius-5-end{
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.z-index-m1 {
  z-index: -1 !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-2 {
  z-index: 2 !important;
}

.z-index-100 {
  z-index: 100 !important;
}


.custom-modal-lg {
  max-width: 85% ;
  min-width: 85% ;
  width: 85% ;
  margin: 6vh auto 12vh !important;
  border-radius: 10px !important;
  padding-bottom: 5rem !important;
}

.custom-modal-md {
  max-width: 60% ;
  min-width: 60% ;
  width: 60% ;
  margin: 6vh auto 12vh !important;
  border-radius: 10px !important;
  padding-bottom: 5rem !important;
}


.custom-modal-sm {
  max-width: 45% ;
  min-width: 45% ;
  width: 45% ;
  margin: 6vh auto 12vh !important;
  border-radius: 10px !important;
  padding-bottom: 5rem !important;
}
.custom-modal-xs {
  max-width: 30% ;
  min-width: 30% ;
  width: 30% ;
  margin: 6vh auto 12vh !important;
  border-radius: 10px !important;
  padding-bottom: 5rem !important;
}

.bg-none{
  background: none!important;
}

.vertical-align-0{
  vertical-align: 0 !important;
  line-height: 0 !important;
}


.break-line {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.spinner {
  -webkit-animation: spin 1.75s linear infinite; /* Safari */
  animation: spin 1.75s linear infinite;
}

/*.ant-table-cell{*/
/*  background: none !important;*/
/*}*/

.anticon>*{
  vertical-align: 0 !important;
  line-height: 0 !important;
 }


/*.ant-dropdown-menu-title-content{*/
/*  width: 100%;*/
/*}*/

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.text-dots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.text-dots-two-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 5px;
}


@media (max-width: 900px) {

  .paper{
    padding: 1rem 1.2rem;
  }

  .custom-modal-lg, .custom-modal-md, .custom-modal-sm, .custom-modal-xs {
    margin-top: 3vh !important;
    max-width: 95% !important;
    min-width: 95% !important;
    width: 95% !important;
  }

  .font-size-10 {
    font-size: 8px !important;
  }

  .font-size-11 {
    font-size: 9px !important;
  }

  .font-size-12 {
    font-size: 10px !important;
  }

  .font-size-13 {
    font-size: 11px !important;
  }

  .font-size-14 {
    font-size: 12px !important;
  }

  .font-size-15 {
    font-size: 13px !important;
  }

  .font-size-16 {
    font-size: 14px !important;
  }

  .font-size-17 {
    font-size: 15px !important;
  }

  .font-size-18 {
    font-size: 16px !important;
  }

  .font-size-20 {
    font-size: 18px !important;
  }

  .font-size-22 {
    font-size: 20px !important;
  }
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
  font-size: 16px;
}
