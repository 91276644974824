:root {
  --PRIMARY_COLOR: #3c3c3b;
  --white: #faf0e6;
  --SECONDARY_1_COLOR: #ebe6e3;
  --SECONDARY_2_COLOR: #89a09b;
  --SECONDARY_3_COLOR: #c9bab1;
  --SECONDARY_4_COLOR: #246f6c;
  --SECONDARY_5_COLOR: #ededed;
}


.main_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: whitesmoke;
}

.logo {
  width: 200px;
}

.side_bar_background {
  background: var(--PRIMARY_COLOR) !important;
}

.side_bar_drawer {
  width: 270px !important;
  background: var(--PRIMARY_COLOR) !important;
}

.ant-menu-submenu-selected {
  color: var(--white);
}

.ant-menu.custom {
  color: var(--white);
  background: var(--PRIMARY_COLOR);
}

.custom .ant-menu-submenu-active {
  color: var(--white) !important;
  background: var(--ant-primary-1);
}

.custom .ant-menu-submenu-active .ant-menu-submenu-title {
  color: var(--white) !important;
  background: var(--ant-primary-1);
}
.custom .ant-menu-submenu-title:hover {
  background: var(--ant-primary-1);
  color: var(--white);
}

.custom .ant-menu-sub.ant-menu-inline {
  background: var(--PRIMARY_COLOR);
  color: var(--white);
}
.custom .ant-menu-submenu-arrow {
  color: var(--white);
  background: var(--white);
}
.custom .ant-menu-submenu-arrow:hover {
  color: var(--white);
  background: var(--white);
}
.custom .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: var(--ant-primary-2);
  color: var(--white);
}
.custom .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background: var(--ant-primary-2);
  color: var(--white);
}

.custom .ant-menu-submenu-selected {
  color: var(--white);
}

.custom .ant-menu-item-active {
  color: var(--white);
  background: var(--PRIMARY_COLOR);
}
.custom .ant-menu-item {
  padding: 0;
  margin: 0;
}

.custom .ant-menu-item-active :hover {
  color: var(--white);
  background-color: transparent;
}
